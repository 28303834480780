import { render } from "./SolicitudesNuevas.vue?vue&type=template&id=0b0ae169"
import script from "./SolicitudesNuevas.vue?vue&type=script&lang=js"
export * from "./SolicitudesNuevas.vue?vue&type=script&lang=js"

import "./SolicitudesNuevas.vue?vue&type=style&index=0&id=0b0ae169&lang=scss"
script.render = render

export default script
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QResizeObserver,QBtn,QMenu,QList,QItem,QIcon,QSeparator,QDialog,QCard,QScrollArea,QCardSection,QCardActions});qInstall(script, 'directives', {ClosePopup});
